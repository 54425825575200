<template>
  <div>
    <headtop></headtop>
    <img class="bannerimg" src="../assets/img/news/banner.png" />
    <div class="pagecenter">
      <img src="../assets/img/gongre/top.png" />
      <img src="../assets/img/gongre/bottom.png" />
    </div>

    <div class="pagebottom">
      <el-row  class="pagerow">
        <el-col :span="7" class="pagecol">
          <img src="../assets/img/gongre/tabone.png" />
          <div class="col-right">
            <p>工资待遇低，劳动强度大</p>
            <div class="right-text">
              中国热企的一线用工，主要集中在60、70后，工资待遇低，且即使什么也不做，同样面临用工风险。
            </div>
          </div>
        </el-col>
        <el-col :span="7" :offset="1" class="pagecol">
            <img src="../assets/img/gongre/tabone.png" />
          <div class="col-right">
            <p>工资待遇低，劳动强度大</p>
            <div class="right-text">
              中国热企的一线用工，主要集中在60、70后，工资待遇低，且即使什么也不做，同样面临用工风险。
            </div>
          </div>
        </el-col>
        <el-col :span="7" :offset="1" class="pagecol">
            <img src="../assets/img/gongre/tabone.png" />
          <div class="col-right">
            <p>工资待遇低，劳动强度大</p>
            <div class="right-text">
              中国热企的一线用工，主要集中在60、70后，工资待遇低，且即使什么也不做，同样面临用工风险。
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row  class="pagerow">
        <el-col :span="7" class="pagecol">
          <img src="../assets/img/gongre/tabone.png" />
          <div class="col-right">
            <p>工资待遇低，劳动强度大</p>
            <div class="right-text">
              中国热企的一线用工，主要集中在60、70后，工资待遇低，且即使什么也不做，同样面临用工风险。
            </div>
          </div>
        </el-col>
        <el-col :span="7" :offset="1" class="pagecol">
            <img src="../assets/img/gongre/tabone.png" />
          <div class="col-right">
            <p>工资待遇低，劳动强度大</p>
            <div class="right-text">
              中国热企的一线用工，主要集中在60、70后，工资待遇低，且即使什么也不做，同样面临用工风险。
            </div>
          </div>
        </el-col>
        <el-col :span="7" :offset="1" class="pagecol">
            <img src="../assets/img/gongre/tabone.png" />
          <div class="col-right">
            <p>工资待遇低，劳动强度大</p>
            <div class="right-text">
              中国热企的一线用工，主要集中在60、70后，工资待遇低，且即使什么也不做，同样面临用工风险。
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <foot></foot>
  </div>
</template>

<script>
import headtop from "../components/header";
import foot from "../components/footer";
export default {
  name: "VueNews",
  components: {
    headtop,
    foot,
  },
  data() {
    return {};
  },
  mounted() {
    window.scrollTo(0,0);
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.bannerimg {
  width: 100%;
  height: 260px;
}
.pagecenter {
  background: #fff;
  padding-top: 60px;
  padding-bottom: 20px;
  img {
    width: 50%;
    margin: 0 auto;
    display: block;
  }
}

.pagebottom {
  background: #efefef;
  height: 517px;
  padding: 82px 0;
  box-sizing: border-box;
  .pagerow {
    width: 76%;
    margin: 0 12%;
    margin-bottom: 25px;
    .pagecol {
      background: #ffffff;
      border-radius: 16px;
      padding: 30px 20px;
      display: flex;
      justify-content: space-between;
      box-shadow:0px 0px 15px  #999;
      img {
        width: 75px;
        height: 75px;
        display: block;
        margin: auto 0;
      }
      .col-right {
          margin-left: 15px;
        p {
          font-size: 22px;
          font-weight: bold;
          color: #223762;
          margin: 0;
          margin-bottom: 10px;
        }
        .right-text {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }
}
</style>