<template>
	<div v-cloak>
		<el-backtop :bottom="100"></el-backtop>
		<!-- 底部部分 -->
		<div class="foot">
			<div class="footContent">
				<!-- 左侧 -->
				<div class="footLeft">
					<div class="footLeftLogo">
						<img src="../assets/icon/footLogoXin.png" alt="" />
					</div>
					<div class="footLeftList">
						<div class="footBotAllCenterMainSupportList">
							<div class="footBotAllCenterMainSupportListItem">
								<div class="footBotAllCenterMainSupportListItemImg">
									<img src="../assets/images/support1.png" />
								</div>
								<div class="footBotAllCenterMainSupportListItemText">
									帮助中心
								</div>
							</div>
							<div class="footBotAllCenterMainSupportListItem">
								<div class="footBotAllCenterMainSupportListItemImgOne">
									<img src="../assets/images/support4.png" />
								</div>
								<div class="footBotAllCenterMainSupportListItemText">
									企业合作咨询热线：400-024-0086 产品使用咨询热线：400-024-0086
								</div>
							</div>
							<div class="footBotAllCenterMainSupportListItem">
								<div class="footBotAllCenterMainSupportListItemImgTwo">
									<img src="../assets/images/support2.png" />
								</div>
								<div class="footBotAllCenterMainSupportListItemText">
									service@odd-job.net
								</div>
							</div>
							<div class="footBotAllCenterMainSupportListItem">
								<div class="footBotAllCenterMainSupportListItemImgThree">
									<img src="../assets/images/support3.png" />
								</div>
								<div class="footBotAllCenterMainSupportListItemText">
									零工儿企业端网址：https://seller.odd-job.net
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 中间 -->
				<div class="footCenter">
					<div class="footCenterItem">
						<div class="footCenterItemTitle">覆盖场景介绍</div>
						<div class="footCenterItemInfo">企业服务</div>
						<div class="footCenterItemInfo">用工平台</div>
						<div class="footCenterItemInfo">企业服务</div>
						<div class="footCenterItemInfo">城乡互通</div>
						<div class="footCenterItemInfo">人才培养</div>
					</div>
					<div class="footCenterItem">
						
						<div class="footCenterItemTitle">零工平台介绍</div>
						<div class="footCenterItemInfo platPointer"  @click="platformIntroduction">平台简介</div>
						<div class="footCenterItemInfo">人才招聘</div>
						<div class="footCenterItemInfo platPointer"  @click="callWe">联系我们</div>
						<div class="footCenterItemInfo">零工职业学校</div>
						<div class="footCenterItemInfo">零工儿产业园</div>
					</div>
					<div class="footCenterItem">
						<div class="footCenterItemTitle">招商加盟</div>
						<div class="footCenterItemInfo">招商政策</div>
						<div class="footCenterItemInfo">招商支持</div>
						<div class="footCenterItemInfo">加盟条件</div>
						<div class="footCenterItemInfo">招商流程</div>
					</div>
				</div>
				<!-- 右侧 -->
				<div class="footRight">
					<div class="footLeftQrcode">
						<div class="footLeftQrcodeList">
							<!-- <div class="footLeftQrcodeItem">
								<div class="footLeftQrcodeItemIcon">
									<img src="../assets/images/pop1.png" />
								</div>
								<p>零工儿小程序</p>
							</div> -->
							<div class="footLeftQrcodeItem">
								<div class="footLeftQrcodeItemIcon">
									<img src="../assets/images/oddjobApp.png" />
								</div>
								<p>零工儿APP</p>
							</div>
							<div class="footLeftQrcodeItem">
								<div class="footLeftQrcodeItemIcon">
									<img src="../assets/images/pop3.png" />
								</div>
								<p>零工儿公众号</p>
							</div>
						</div>
					</div>
					<div class="footRightMain">
						<div class="footRightTitle">
							<p>产品使用咨询热线 | <span>400-024-0086</span></p>
						</div>
					</div>
					<!-- <div class="footRightInput">
						<el-input placeholder="搜索关键词" size="small" v-model="search">
							<el-button slot="append" icon="el-icon-search"></el-button>
						</el-input>
					</div> -->
				</div>
			</div>
			<div class="copyright">
				Copyright <span >©</span>零工儿（沈阳）网络开发有限公司 All Rights Reserved. |
				辽ICP备2020013587号-1
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Footer",
		data() {
			return {
				search: "",
			};
		},
		methods: {
			platformIntroduction() {

				
				this.$router.push({
					name: 'PlatformIntroduction',
					params: {
						content: '1',
					}
				})

			},
			callWe() {
				this.$router.push({
					name: 'PlatformIntroduction',
					params: {
						content: '4',
					}
				})
			}
		}
	};
</script>
<style scoped lang="scss">
	[v-cloak] {
		display: none;
	}

	.foot {
		background-color: #3d3d3d;
		width: 100%;
		max-width: 100%;
		min-width: 1280px;
		// min-width: 1200px;
		// height: 528px;

		.footContent {
			width: 1280px;
			min-width: 1280px;
			// width: 1200px;
			// min-width: 1200px;
			display: flex;
			display: -ms-flexbox;
			justify-content: space-between;
			-ms-flex-pack: justify;
			margin: 0 auto;
			padding-top: 50px;
			padding-bottom: 50px;

			.footLeft {
				.footLeftLogo {
					img {
						width: 223px;
						height: 62px !important;
					}
				}

				.footLeftList {
					padding: 20px 0 0;

					.footBotAllCenterMainSupportListItem {
						padding-bottom: 5px;
						color: #ffffff;
						font-size: 14px;
						display: flex;
						display: -ms-flexbox;
						flex-direction: row;
						-ms-flex-direction: row;
					}

					.footBotAllCenterMainSupportListItem .footBotAllCenterMainSupportListItemText {
						height: 24px;
						line-height: 24px;
					}

					.footBotAllCenterMainSupportListItemImg {
						width: 15px;
						height: 20px;
						padding-right: 10px;
					}

					.footBotAllCenterMainSupportListItemImg img {
						width: 10px;
						height: 10px;
						vertical-align: middle;
					}

					.footBotAllCenterMainSupportListItemImgOne {
						width: 15px;
						height: 20px;
						padding-right: 10px;
					}

					.footBotAllCenterMainSupportListItemImgOne img {
						width: 10px;
						height: 10px;
					}

					.footBotAllCenterMainSupportListItemImgTwo {
						width: 15px;
						height: 20px;
						padding-right: 10px;
					}

					.footBotAllCenterMainSupportListItemImgTwo img {
						width: 10px;
						height: 10px;
					}

					.footBotAllCenterMainSupportListItemImgThree {
						width: 15px;
						height: 20px;
						padding-right: 10px;
					}

					.footBotAllCenterMainSupportListItemImgThree img {
						width: 10px;
						height: 10px;
					}

					.footBotAllCenterMainEcode {
						display: flex;
						display: -ms-flexbox;
						-ms-flex-pack: justify;
						justify-content: space-between;
						-ms-flex-pack: justify;
						align-items: center;
						-ms-flex-align: center;
					}

					.footBotAllCenterMainEcodeItem {
						display: flex;
						display: -ms-flexbox;
						flex-direction: column;
						-ms-flex-direction: column;
						margin-right: 10px;
					}

					.footBotAllCenterMainEcodeItemImg {
						padding: 5px;
						background-color: #2d343f;
						transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
						/* box-shadow: 0 0 10px #528CF7; */
					}

					.footBotAllCenterMainEcodeItemImg :hover {
						box-shadow: 0 0 10px #528cf7;
						-moz-box-shadow: 0 0 10px #528cf7;
						-webkit-box-shadow: 0 0 10px #528cf7;
						-ms-box-shadow: 0 0 10px #528cf7;
						transform: scale3d(1.2, 1.2, 1);
					}

					.footBotAllCenterMainEcodeItemImg img {
						width: 110px;
						height: 110px;
					}

					.footBotAllCenterMainEcodeItemText {
						background-color: #2d343f;
						text-align: center;
						color: #7c868d;
						font-size: 12px;
						padding: 0 0 10px 0;
					}

					.footBotAllCenterLast {
						margin-top: 50px;
						color: #78808d;
						font-size: 14px;
						padding-bottom: 50px;
					}
				}
			}

			.footCenter {
				display: flex;
				display: -ms-flexbox;
				justify-content: space-between;
				-ms-flex-pack: justify;
				padding-top: 15px;

				.footCenterItem {
					color: #ffffff;

					.footCenterItemTitle {
						position: relative;
						font-size: 16px;
						padding-bottom: 10px;

						&::before {
							content: "";
							position: absolute;
							left: 0;
							top: -10px;
							width: 40%;
							border-top: 1px solid #fff;
						}
					}

					.footCenterItemInfo {
						font-size: 14px;
						margin: 15px 0;
						color: #acabab;
					}
				}

				.footCenterItem+.footCenterItem {
					margin-left: 40px;
				}
			}

			.footRight {
				display: flex;
				flex-direction: column;
				
				align-items: center;

				.footRightMain {
					.footRightTitle {
						margin-top: 14px;
						// margin-left: 85px;
						text-align: left;
						color: #ffffff;

						p {
							font-size: 14px;

							span {
								font-size: 24px;
								font-weight: bold;
							}
						}
					}
				}

				.footRightInput {
					text-align: right;
					margin-top: 10px;

					::v-deep .el-input__inner {
						background-color: transparent;
						border-radius: 50px 0 0 50px;
						border-right-width: 0;
					}

					::v-deep .el-input-group__append {
						background-color: transparent;
						border-radius: 0 50px 50px 0;
					}
				}
			}
		}
	}

	.footLeftQrcode {
		height: 117px;
		display: flex;

		.footLeftQrcodeList {
			display: flex;
			justify-content: space-around;
			align-items: center;

			.footLeftQrcodeItem {
				text-align: center;

				p {
					margin-top: 10px;
					color: #fff;
					font-size: 12px;
				}

				.footLeftQrcodeItemIcon {
					img {
						width: 80px;
						height: 80px;
					}
				}
			}

			.footLeftQrcodeItem+.footLeftQrcodeItem {
				margin-left: 22px;
			}
		}
	}

	.el-backtop {
		display: flex;
		display: -ms-flexbox;
		justify-content: center;
		-ms-flex-pack: center;
		align-items: center;
		-ms-flex-align: center;
	}

	.copyright {
		padding-bottom: 72px;
		font-size: 12px;
		text-align: center;
		color: #fff;
	}
	.platPointer{
		cursor: pointer;
	}
	.copyright span{
		
		margin-left:5px;
		margin-right: 5px;
		}
</style>
