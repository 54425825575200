<template>
	<div>
		<!-- 顶部定位及注册登录部分 -->

		<Header />

		


	<div class="title">
		<div class="titleLeft" @click="toRlm">
			<div class="titleLeftNav"><b>热了吗</b><b><span>·</span>舒适家暖居商城</b></div>

		</div>
		<div class="titleRightAll">
			<div class="titleRight " @click="returnHome">
				<div>网站首页</div>
				<div class="lineView"></div>

			</div>
			<div class="titleRight" @click="solution">
				<div>解决方案</div>
				<div class="lineView"></div>
			</div>
			<div class="titleRight" @click="industrialPark">
				<div>数字产业园</div>
				<div class="lineView"  style="background-color: #ff8041;"></div>
			</div>
			<div class="titleRight" @click="investmentPromotion">
				<div>招商加盟</div>
				<div class="lineView"></div>
			</div>
			<div class="titleRight" @click="journalism">
				<div>新闻中心</div>
				<div class="lineView"></div>
			</div>
			<div class="titleRight" @click="helpCenter">
				<div>帮助中心</div>
				<div class="lineView"></div>
			</div>
			<div class="titleRight" @click="aboutWe">
				<div>关于零工儿</div>
				<div class="lineView"></div>
			</div>
		</div>
	</div>









	<!-- <smallHeader />
		<div class="bannerDiv">
			
			<div class="titleDiv">
			
			
				<img src="../assets/introduction/logo.png" class="logoImg">
				<div class="titleDivRightDiv">
					<div @click="solution" class="platPointer">解决方案</div>
					<div @click="industrialPark" class="platPointer">数字产业园</div>
					<div @click="investmentPromotion" class="platPointer">招商加盟</div>
					<div @click="journalism" class="platPointer">新闻中心</div>
					<div @click="helpCenter" class="platPointer">帮助中心</div>
					<div @click="aboutWe" class="platPointer">关于零工儿</div>
					<div class="platPointer">
						<span @click="returnHome">返回首页</span>
						<img src="../assets/introduction/returnHomePage.png" class="returnHomePage">
					</div>
				</div>
			</div> -->

	<div class="bannerDiv">
		<img src="../assets/banner/industrialParkBanner.jpg">
		<!-- 	<div class="familyThreeDiv">
				<div class="familyThreeDivCompany">
					<div class="familyDiv">
						<img src="../assets/introduction/familyLogo.png" class="familyLogoImg">
						<div class="familyDivTitle">
							<div>企业 | 家庭 | 市场</div>
							<div>「惠民利企」</div>
						</div>
					</div>
					<div class="familyThreeDivCompanyContent">
						以产业集群为抓手
						为各中小企业赋能，为企业打造良好营商环境。
					</div>
				</div>
				<img src="../assets/industrialPark/industrialParkTop.png" class="familyThreeImg">
			</div> -->



	</div>
	<div class="lawAbiding">
		<div>Industrial park Odd-job</div>
		<div>守法 利他 合规 真实</div>
	</div>
	<div style="background-color: #f3f3f3;height:auto;">

		<div class="dividendView">
			<div class="dividendViewTitleView">企业智慧首选&nbsp;&nbsp;&nbsp;&nbsp;入驻即享红利</div>
			<div class="dividendViewContentView">
				<div>零工儿数字产业园引爆零工市场 积极为各中小企业赋能</div>
				<div>&nbsp;&nbsp;&nbsp;&nbsp;互联网技术与共享经济的兴起，
					导致常规的工作概念边界越来越模糊，
					近年来，“零工经济”打破传统就业模式，
					使劳动力市场发生着蜕变。在政府倡导互联网+时代的大背景下，
					加之新冠肺炎疫情的持续影响，进一步推动了从事零工经济的群体发展壮大，
					并呈现出快速增长态势。根据有关部门的数据，目前中国的零工群体已超过一亿人
					，“互联网+零工经济”的催生下，辽宁零工儿数字产业园应运而生。</div>
			</div>
		</div>
		<div class="projectIntroductionView">
			<div class="projectIntroductionView-contentView">
				<div class="projectIntroductionView-contentView-sonView"
					:class="{borderBottm:projectIntroductionImgClass}" @click="projectIntroductionImgClick">
					<img :src="projectIntroductionImg">
					<div>项目介绍</div>



				</div>
				<div class="projectIntroductionView-contentView-sonView"
					:class="{borderBottm:projectAdvantagesImgClass}" @click="projectAdvantagesImgClick">
					<img :src="projectAdvantagesImg">

					<div>项目优势</div>
				</div>
				<div class="projectIntroductionView-contentView-sonView" :class="{borderBottm:operationPatternImgClass}"
					@click="operationPatternImgClick">
					<img :src="operationPatternImg">
					<div>运作模式</div>
				</div>

				<div class="projectIntroductionView-contentView-sonView" :class="{borderBottm:parkParkPlanningImgClass}"
					@click="parkParkPlanningImgClick">
					<img :src="parkParkPlanningImg">
					<div>园中园规划</div>
				</div>
				<div class="projectIntroductionView-contentView-sonView"
					:class="{borderBottm:scopeOfBusinessClickClass}" @click="scopeOfBusinessClick">
					<img :src="scopeOfBusiness">
					<div>业务范围</div>
				</div>

			</div>

		</div>

		<div class="contentView">
			<div v-if="contentViewOne">
				&nbsp;&nbsp;&nbsp;&nbsp;辽宁零工儿数字产业园坐落于盘锦辽河口生态经济区，已于今年3月投入运营，
				与政府有关部门（税务、市场监督、公安等）搭建多部门协同的“智慧集群注册监管平台”，
				负责数字产业企业的招商运营和相关自然人注册为个体工商户的托管服务，
				即先采用虚拟地址、名称认同，实现执照的“不见面发放”，将自然人直接注册为个体工商户。
			</div>

			<div v-if="contentViewTwo">
				1、企业入驻产业园尊享一站式服务，法人无需到场实名采集。<br>
				2、享受政府奖励政策。<br>
				3、无门槛，只需将公司注册或迁移至产业园即可。<br>
				4、个体工商户定期定额免增值税、免企业所得税、免个税。<br>
				5、免房租、免房产税、免土地使用税。<br>
				6、税控盘免费、代账免账薄费。<br>
			</div>



			<div v-if="contentViewThree">
				辽宁零工儿数字产业园以产业集群为抓手，
				积极探索“园中园”发展模式，全面推进虚拟产业园区规划建设。
				如果说企业内部产业链条的延长是针对某几个环节补链、强链、壮链，
				那么产业园区的链条延伸，则是企业间的强强联合，在延长产业链条的同时，
				也助推着整个产业向高端进军。
				在园区建设和招商引资中探索发展“以商建园、以园招商、以商兴园”新路子。
				园区充分发挥“园中园”的优势，不断健全招商体系、拓展招商领域、探求招商策略，
				吸引企业到盘锦投资兴业；依托先进的运营管理理念，组建专业运营管理团队，
				做好园区规划、建设、服务、管理，不断提升服务功能，
				提高服务水平，为企业打造良好的营商环境；
				不断引入优质企业，形成产业集群，加快产业集聚，增加园区活力。
			</div>

			<div v-if="contentViewFour">
				1、智慧热企数字产业园<br>
				2、农民工互助产业园<br>
				3、电脑医院产业园<br>
				4、人力资源服务产业园<br>
				5、上门儿服务产业园<br>
				6、蹲活儿服务产业园<br>
			</div>

			<div v-if="contentViewFive">
				辽宁零工儿产业园业务范围包括：用工财税综合解决方案/属地化财税解决方案/财务外包/税务代理/税务筹划/代理记账/工商代办/融资贷款/高企认定/补贴申报/人力外包服务/孵化财税/财税培训/智能代发/众包服务/为地方政府集聚财税资源。具体包括：
				1、公司注册：注册公司、银行开户、注册资本变更、公司变更、公司年审、公司注销、公司刻章。<br>
				2、代理记账：记账报税，税务代办，财务咨询。<br>
				3、税务筹划：税务风险防范及咨询，税筹方案设计。<br>
				4、资质代办：劳务派遣许可证，食品经营许可证、卫生许可证，人力资源服务许可证等资质代办。<br>
			</div>

		</div>
	</div>


	<Footer></Footer>
	</div>
</template>

<script>
	import {
		// getBanner,
		// getInfo,
		getCategoryList,
		// getAdvisory,
	} from "../request/api.js";
	import Header from "../components/header";
	// import smallHeader from "../components/smallHeader.vue";
	import Footer from "../components/footer";
	export default {
		name: "index",
		data() {
			return {
				aboutShow: true,
				categoryList: "", // 商城目录

				CultureShow: false,
				callWeShow: false,
				projectIntroductionImgClass: true, //项目介绍
				projectAdvantagesImgClass: false, //项目优势
				operationPatternImgClass: false, //运作模式
				parkParkPlanningImgClass: false, //园中园规划
				scopeOfBusinessClickClass: false, //业务范围

				projectIntroductionImg: require("../assets/industrialPark/projectIntroductionImg.png"), //项目介绍
				projectAdvantagesImg: require("../assets/industrialPark/projectAdvantagesImg.png"), //项目优势
				operationPatternImg: require("../assets/industrialPark/operationPatternImg.png"), //运作模式
				parkParkPlanningImg: require("../assets/industrialPark/parkParkPlanningImg.png"), //园中园规划
				scopeOfBusiness: require("../assets/industrialPark/scopeOfBusiness.png"), //业务范围
				contentViewOne: true,
				contentViewTwo: false,
				contentViewThree: false,
				contentViewFour: false,
				contentViewFive: false,


			}
		},
		created() {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
			// this.first()
			this.getMailList();
		},

		components: {
			Footer,
			// smallHeader
			Header
		},


		methods: {
			toRlm() {
				window.open("http://www.areyouhot.cn");
			},
			getMailList() {
				let params = {
					app_type: "pc",
					city_site_id: 2,
				};
				getCategoryList(params).then((res) => {
					// console.log("getMailList", res);
					this.categoryList = res.data[0].child_list;
				});
			},

			investmentPromotion() {
				this.$router.push({
					name: 'investmentPromotion',
					params: {
						content: '1',
					}
				})
			},
			solution() {
				this.$router.push({
					name: 'solution'
				})
			},
			industrialPark() {
				this.$router.push({
					name: 'industrialPark',

				})
			},
			journalism() {
				this.$router.push({
					name: 'journalism',

				})
			},

			helpCenter() {
				this.$router.push({
					name: 'helpcenter',

				})
			},
			aboutWe() {
				this.$router.push({
					name: 'PlatformIntroduction',
					params: {
						content: '1',
					}
				})
				localStorage.setItem("palt","1")

			},
			returnHome() {
				this.$router.push({
					name: 'index',
					params: {
						content: '1',
					}
				})
			},
			//点击项目介绍
			projectIntroductionImgClick() {
				this.projectIntroductionImgClass = true //项目介绍
				this.projectAdvantagesImgClass = false //项目优势
				this.operationPatternImgClass = false //运作模式
				this.parkParkPlanningImgClass = false //园中园规划
				this.scopeOfBusinessClickClass = false //业务范围
				this.contentViewOne = true
				this.contentViewTwo = false
				this.contentViewThree = false
				this.contentViewFour = false
				this.contentViewFive = false
				this.projectIntroductionImg = require("../assets/industrialPark/projectIntroductionImg-green.png") //项目介绍
				this.projectAdvantagesImg = require("../assets/industrialPark/projectAdvantagesImg.png") //项目优势
				this.operationPatternImg = require("../assets/industrialPark/operationPatternImg.png") //运作模式
				this.parkParkPlanningImg = require("../assets/industrialPark/parkParkPlanningImg.png") //园中园规划
				this.scopeOfBusiness = require("../assets/industrialPark/scopeOfBusiness.png") //业务范围
			},
			//点击项目优势
			projectAdvantagesImgClick() {
				this.projectIntroductionImgClass = false //项目介绍
				this.projectAdvantagesImgClass = true //项目优势
				this.operationPatternImgClass = false //运作模式
				this.parkParkPlanningImgClass = false //园中园规划
				this.scopeOfBusinessClickClass = false //业务范围
				this.contentViewOne = false
				this.contentViewTwo = true
				this.contentViewThree = false
				this.contentViewFour = false
				this.contentViewFive = false

				this.projectIntroductionImg = require("../assets/industrialPark/projectIntroductionImg.png") //项目介绍
				this.projectAdvantagesImg = require("../assets/industrialPark/projectAdvantagesImg-green.png") //项目优势
				this.operationPatternImg = require("../assets/industrialPark/operationPatternImg.png") //运作模式
				this.parkParkPlanningImg = require("../assets/industrialPark/parkParkPlanningImg.png") //园中园规划
				this.scopeOfBusiness = require("../assets/industrialPark/scopeOfBusiness.png") //业务范围
			},
			//点击运作模式
			operationPatternImgClick() {
				this.projectIntroductionImgClass = false //项目介绍
				this.projectAdvantagesImgClass = false //项目优势
				this.operationPatternImgClass = true //运作模式
				this.parkParkPlanningImgClass = false //园中园规划
				this.scopeOfBusinessClickClass = false //业务范围
				this.contentViewOne = false
				this.contentViewTwo = false
				this.contentViewThree = true
				this.contentViewFour = false
				this.contentViewFive = false

				this.projectIntroductionImg = require("../assets/industrialPark/projectIntroductionImg.png") //项目介绍
				this.projectAdvantagesImg = require("../assets/industrialPark/projectAdvantagesImg.png") //项目优势
				this.operationPatternImg = require("../assets/industrialPark/operationPatternImg-green.png") //运作模式
				this.parkParkPlanningImg = require("../assets/industrialPark/parkParkPlanningImg.png") //园中园规划
				this.scopeOfBusiness = require("../assets/industrialPark/scopeOfBusiness.png") //业务范围

			},
			//点击园中园规划
			parkParkPlanningImgClick() {
				this.projectIntroductionImgClass = false //项目介绍
				this.projectAdvantagesImgClass = false //项目优势
				this.operationPatternImgClass = false //运作模式
				this.parkParkPlanningImgClass = true //园中园规划
				this.scopeOfBusinessClickClass = false //业务范围
				this.contentViewOne = false
				this.contentViewTwo = false
				this.contentViewThree = false
				this.contentViewFour = true
				this.contentViewFive = false

				this.projectIntroductionImg = require("../assets/industrialPark/projectIntroductionImg.png") //项目介绍
				this.projectAdvantagesImg = require("../assets/industrialPark/projectAdvantagesImg.png") //项目优势
				this.operationPatternImg = require("../assets/industrialPark/operationPatternImg-green.png") //运作模式
				this.parkParkPlanningImg = require("../assets/industrialPark/parkParkPlanningImg-green.png") //园中园规划
				this.scopeOfBusiness = require("../assets/industrialPark/scopeOfBusiness.png") //业务范围
			},
			//点击业务范围
			scopeOfBusinessClick() {
				this.projectIntroductionImgClass = false //项目介绍
				this.projectAdvantagesImgClass = false //项目优势
				this.operationPatternImgClass = false //运作模式
				this.parkParkPlanningImgClass = false //园中园规划
				this.scopeOfBusinessClickClass = true //业务范围
				this.contentViewOne = false
				this.contentViewTwo = false
				this.contentViewThree = false
				this.contentViewFour = false
				this.contentViewFive = true
				this.projectIntroductionImg = require("../assets/industrialPark/projectIntroductionImg.png") //项目介绍
				this.projectAdvantagesImg = require("../assets/industrialPark/projectAdvantagesImg.png") //项目优势
				this.operationPatternImg = require("../assets/industrialPark/operationPatternImg.png") //运作模式
				this.parkParkPlanningImg = require("../assets/industrialPark/parkParkPlanningImg.png") //园中园规划
				this.scopeOfBusiness = require("../assets/industrialPark/scopeOfBusiness-green.png") //业务范围
			}



		}

	};
</script>

<style scoped="scoped" lang="scss">
	$font-color: #000000;
	$light-color: #f76a00;
	$white: #ffffff;

	.bannerDiv {

		// width: 100%;
		// height: 490px;
		// margin: 0 auto;
		// // margin-top: 61px;
		// box-sizing: border-box;
		// // margin-bottom: 45px;
		// // transform: scale(0.8);
		// // margin-top: -20px;
		// // margin-bottom: -20px;
		// margin-top: 14px;
		// overflow: hidden;
		// width: 1440px;
		// width: 100%;
		height: 490px;
		margin: 0 auto;
		// margin-top: 61px;
		box-sizing: border-box;
		
		margin-top: 14px;
		overflow: hidden;
		width:100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		

	}

	.bannerDiv img {
		
		height: 100%;
	}

	.logoImg {
		width: 197px;
		height: 52px;
	}

	.familyThreeDiv {

		width: 1039px;
		height: 532px;
		margin-top: 80px;
		position: relative;
		margin: 0 auto;
	}

	.familyThreeImg {


		width: 828px;
		height: 532px;
		position: absolute;
		right: 0;


	}

	.familyThreeDivCompany {

		width: 499px;
		height: 396px;
		position: absolute;
		background: linear-gradient(91deg, #f4f6f5 0%, #ffffff 100%);
		left: 0;
		z-index: 11;
		top: 95px;
	}

	.familyDiv {

		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		/* align-items: flex-end; */

		/* background-color: red; */
	}

	.familyLogoImg {

		height: 163px;
		width: 168px;

		margin-top: 47px;
		margin-left: 24px;
	}

	.familyDivTitle {

		margin-top: 105px;
	}

	.familyDivTitle div:nth-child(1) {
		font-size: 36px;
		font-weight: 400;
		text-align: left;
		color: #828282;
		line-height: 52px;
	}

	.familyDivTitle div:nth-child(2) {
		font-size: 48px;
		font-weight: 400;
		text-align: LEFT;
		color: #105697;
		line-height: 45px;
		margin-top: 17px;
	}

	.familyThreeDivCompanyContent {

		font-size: 19px;
		font-weight: Extra Light;
		text-align: LEFT;
		color: #828282;
		margin-top: 57px;
		width: 386px;
		margin-left: 49px;
	}


	.lawAbiding {
		width: 100%;
		height: 276px;
		background: #105697;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		/* 276-115=161 */
		padding-top: 61px;
		padding-bottom: 100px;
		box-sizing: border-box;
	}

	.lawAbiding div:nth-child(1) {
		font-size: 40px;
		font-weight: bold;
		text-align: left;
		color: #fefefe;
		line-height: 21px;
	}

	.lawAbiding div:nth-child(2) {
		font-size: 40px;
		font-weight: 400;
		text-align: left;
		color: #fefefe;
		line-height: 21px;
	}

	.callPhoneView {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		margin-top: 105px;
		margin-bottom: 250px;
	}



	.dividendView {
		/* height: 389px; */
		height: 237px;
		// width: 995px;
		width:1280px;
		// padding: 0 537px 0 370px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		padding-top: 60px;
		padding-bottom: 92px;
		/* padding: 60px 0 92px 0; */
		margin: 0 auto;
	}

	.dividendViewTitleView {
		font-size: 36px;
		font-weight: 400;
		text-align: center;
		margin: 0 auto;
		color: #2d83d3;

	}

	.dividendViewContentView {
		// width: 1100px;
		width: 1280px;
		height: 160px !important;
		font-size: 16px;
		font-weight: 400;
		text-align: left;
		color: #666666;
		line-height: 36px;
		margin-top: 41px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;

	}

	.dividendViewContentView div:nth-child(1) {
		line-height: 0;
	}

	.projectIntroductionView {
		width: 100%;
		height: 83px;
		background-color: #fff;
	}

	.projectIntroductionView-contentView {
		// width: 1160px;
		width: 1280px;
		height: 100%;
		margin: 0 auto;
		background-color: #fff;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.projectIntroductionView-contentView img {
		width: 29px;
		height: 29px;
		margin-right: 16px;
	}

	.projectIntroductionView-contentView-sonView {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		font-size: 17px;

	}

	.contentView {
		/* width: 1100px; */
		// width: 995px;
		width: 1280px;
		font-size: 16px;
		font-weight: 400;
		text-align: left;
		color: #666666;
		line-height: 36px;

		margin-top: 50px;
		padding-bottom: 200px;
		height: 237px;
		/* width: 995px; */
		// padding: 0 537px 0 370px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		padding-top: 60px;
		padding-bottom: 92px;
		margin: 0 auto;
		
	}

	.borderBottm {
		border-bottom: 3px solid #2C80C1;
		font-size: 20px;

		font-weight: 700;
		text-align: left;
		color: #2c80c1;
		line-height: 24px;

	}

	.borderBottm img,
	.borderBottm div {
		margin-top: 20px;

	}

	.contentView div {
		width: 1280px;
	}

	.titleDiv {
		width: 100%;
		/* height: 52px; */
		display: flex;
		font-size: row;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 80px;


	}

	.titleDivRightDiv {
		/* width: 6.21rem; */
		/* width: 621px; */
		width: 1000px;
		height: 100%;

		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center
	}

	.titleDivRightDiv div {

		font-size: 16px;


		text-align: CENTER;
		color: #2f2e2e;
	}

	.logoImg {
		width: 197px;
		height: 52px;
	}

	.platPointer {
		cursor: pointer;
	}

	.returnHomePage {
		width: 13px;
		height: 13px;
		margin-left: 7px;
	}

	

	.title {
		width: 1280px;
		
		box-sizing: border-box;
		min-width: 1280px;
		
		margin: 0 auto;
		height: 35px;
		position: relative;
		z-index: 999;
		display: flex;
		justify-content: space-between;
		margin-bottom: 5px;


		.titleLeft {
			box-sizing: border-box;
			// width: 240px;
			// width: 360px;
			color: $white;
			float: left;
			// overflow: hidden;


			.titleLeftNav {
				// font-size: 15px;
				// font-size: 15px;
				font-size: 15px;
				// line-height: 30px;
				text-align: center;
				// border-bottom: 5px solid #ff8041;
				// box-sizing: border-box;
				width: 344px;
				height: 43px;
				background: #ff8041;
				padding-bottom: 5px;
				font-weight: bold;
				color: #ffffff;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				// padding-bottom: 5px;


				b:nth-of-type(1) {
					font-size: 16px;
					font-weight: 700;


				}


				b:nth-of-type(2) {
					font-weight: 400;

					span {
						padding: 0 10px;
					}


				}
			}

			.bigImgLeft {
				overflow: hidden;
				height: 99%;
				z-index: 999;
				position: absolute;
				height: 653px;
				// width: 240px;
				width: 346px;
				background: #394144;
				box-sizing: border-box;
				padding: 0 31px;
				opacity: 0.9;

				// overflow-y: scroll;

				.bigImgLeftItem {
					width: 100%;
					margin: 0 auto;
					// border-bottom: 1px solid rgba($color: $white, $alpha: 0.3);
					color: $white;
					// padding-bottom: 20px;
					// padding: 30px 0;
					box-sizing: border-box;
					margin-top: 20px;
					// height:126px;
					// background-color: red;


					.bigImgLeftItemTitle {
						font-weight: bold;
						box-sizing: border-box;
						cursor: pointer;
						// padding: 20px 0 15px;
						display: flex;
						flex-direction: row;
						justify-content: flex-start;
						align-items: center;
						padding-top: 30px;
						box-sizing: border-box;
						margin-bottom: 14px;


						.bigImgLeftItemTitleText {
							padding-left: 15px;
							// font-size: 14px;
							font-size: 19px;
							border-left: 1px solid #ffffff;
							color: #fff;


						}

						.bigImgLeftItemTitleText:hover {
							color: #fa7c25;
						}
					}

					.bigImgLeftItemInfo {
						// font-size: 14px;
						font-size: 16px;
						line-height: 16px;
						cursor: pointer;
						color: rgba($color: #fff, $alpha: 0.7);



						.bigImgLeftItemInfoInner {
							display: inline-block;
							padding: 8px 0;



						}

						.line {
							padding: 0 10px;
						}

						.bigImgLeftItemInfoInner:hover {
							color: #fa7c25;
						}
					}
				}
			}

			img {
				width: 20px;
				height: 20px;
				vertical-align: middle;
				margin-right: 5px;
				padding-left: 20px;
			}
		}

		.titleLeft:hover {
			cursor: pointer;
		}

		.titleRightAll {
			display: flex;


			.titleRight {
				width: 100px;
				line-height: 30px;
				font-size: 17px;
				text-align: center;
				color: #333333;
				margin-left: 20px;
				cursor: pointer;
				padding-bottom: 2px;
				padding-top: 2px;

				.lineView {
					// border-bottom-left-radius: 3px !important;
					// border-bottom-right-radius: 3px !important;
					// border-bottom: 5px solid #ebeaea;
				width: 100%;
				height: 5px;
				background-color: #ebeaea;
				border-radius: 10px;
				margin-top: 5px;

					&.active,
					&:hover {
						width: 100%;
						height: 5px;
						background-color: #ff8041;
						border-radius: 10px;

						// border-bottom-color: #ff8041;
						// border-bottom-left-radius: 3px !important;
						// border-bottom-right-radius: 3px !important;
					}
				}




			}

			// .titleRight+.titleRight {
			// 	&::before {
			// 		content: "";
			// 		position: absolute;
			// 		top: 50%;
			// 		left: 0;
			// 		margin-top: -9px;
			// 		width: 2px;
			// 		height: 18px;
			// 		background-color: #e2e0e0;
			// 	}
			// }
		}
	}







</style>
