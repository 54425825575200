<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		name: "App",
	};
</script>

<style lang="scss">
	* {
		margin: 0;
		padding: 0;
	}

	html {
		font-family: "Microsoft YaHei", "微软雅黑";
	}

	@media only screen and (max-width: 1440px) {
		.navListMain {
			min-width: 1280px !important;

		}

		.navTop,
		.notice,
		.boxAll {
			width: 1280px !important;
			min-width: 1280px !important;

		}

		.title {
			width: 1280px !important;

		}

		.boxLeft>img {
			width: 320px !important;
		}

		.boxRight .boxDetailOne .radio-group .el-radio-button__inner {
			width: 150px !important;
		}

		.friend {
			>img {
				width: 1280px;

			}
		}

		.boxDetailTwo,
		.boxDetailThree,
		.boxDetailFour {
			width: 900px !important;
		}
	}

	@media only screen and (min-width: 1441px) and (max-width: 1680px) {

		.navListMain {
			min-width: 1280px !important;

		}

		.navTop,
		.notice,
		.boxAll {
			width: 1280px !important;
			min-width: 1280px !important;

		}

		.title {
			width: 1280px !important;

		}

		.boxLeft>img {
			width: 333px !important;
		}

		.boxRight .boxDetailOne .radio-group .el-radio-button__inner {
			width: 150px !important;
		}

		.friend {
			>img {
				width: 1280px;

			}
		}

		.boxDetailTwo,
		.boxDetailThree,
		.boxDetailFour {
			width: 845px !important;
		}



	}

	@media only screen and (min-width: 1681px) {
		// 	.navListMain {
		// 		// min-width: 1440px !important;
		// 		min-width: 1380px !important;
		// 	}

		// 	.navTop,
		// 	.notice,
		// 	.boxAll {
		// 		// width: 1440px !important;

		// 		// min-width: 1440px !important;
		// 		// width: 1380px !important;
		// 		// min-width: 1380px !important;
		// 		width: 1280px !important;
		// 		min-width: 1280px !important;
		// 	}

		// 	.title {
		// 		// width: 1440px !important;
		// 		// width: 1380px !important;
		// 		width: 1280px !important;
		// 	}

		// 	.boxLeft>img {
		// 		width: 360px !important;
		// 	}

		// 	.boxRight .boxDetailOne .radio-group .el-radio-button__inner {
		// 		width: 164px !important;
		// 	}

		// 	.friend {
		// 		>img {
		// 			// width: 1440px;
		// 			// width: 1380px;
		// 			width: 1280px;
		// 		}
		// 	}

		// 	.boxDetailTwo,
		// 	.boxDetailThree,
		// 	.boxDetailFour {
		// 		width: 984px !important;
		// 	}
		// }








		.navListMain {
			min-width: 1280px !important;

		}

		.navTop,
		.notice,
		.boxAll {
			width: 1280px !important;
			min-width: 1280px !important;

		}

		.title {
			width: 1280px !important;

		}

		.boxLeft>img {
			width: 333px !important;
		}

		.boxRight .boxDetailOne .radio-group .el-radio-button__inner {
			width: 150px !important;
		}

		.friend {
			>img {
				width: 1280px;

			}
		}

		.boxDetailTwo,
		.boxDetailThree,
		.boxDetailFour {
			width: 845px !important;
		}
	}
</style>
