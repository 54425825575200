import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../index/index'
import news from '../views/news'
import gongre from '../views/gongre'
import other from '../views/other'
import enterprise from '../views/enterprise'
import worker from '../views/worker'
import user from '../views/user'
import todayrezhao from '../views/todayrezhao'
import PlatformIntroduction from '../views/PlatformIntroduction'
import helpcenter from '../views/helpcenter'
import industrialPark from '../views/industrialPark'
import journalism from '../views/journalism'
import investmentPromotion from '../views/investmentPromotion'
import Solution from '../views/solution'
import oddjobapp from '../views/oddjobapp'
import secondaryPage from '../views/secondaryPage'
import journalismDetails from '../views/journalismDetails'
import squattingActivity from '../views/squattingActivity'


Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'index',
		component: index
	},
	{
		path: '/news',
		name: 'news',
		component: news
	},
	{
		path: '/gongre',
		name: 'gongre',
		component: gongre
	},
	{
		path: '/other',
		name: 'other',
		component: other
	},
	{
		path: '/enterprise',
		name: 'enterprise',
		component: enterprise
	},
	{
		path: '/worker',
		name: 'worker',
		component: worker
	},
	{
		path: '/squattingActivity',
		name: 'squattingActivity',
		component: squattingActivity
	},

	{
		path: '/user',
		name: 'user',
		component: user
	},
	{
		path: '/todayrezhao',
		name: 'todayrezhao',
		component: todayrezhao
	},
	{
		path: '/PlatformIntroduction',
		name: 'PlatformIntroduction',
		component: PlatformIntroduction
	},
	{
		path: '/helpcenter',
		name: 'helpcenter',
		component: helpcenter
	},
	{
		path: '/industrialPark',
		name: 'industrialPark',
		component: industrialPark
	},
	{
		path: '/journalism',
		name: 'journalism',
		component: journalism
	},
	{
		path: '/investmentPromotion',
		name: 'investmentPromotion',
		component: investmentPromotion
	},
	{
		path: '/solution',
		name: 'solution',
		component: Solution
	},
	{
		path: '/oddjobapp',
		name: 'oddjobapp',
		component: oddjobapp
	},
	{
		path: '/secondaryPage',
		name: 'secondaryPage',
		component: secondaryPage
	},
	{
		path: '/journalismDetails',
		name: 'journalismDetails',
		component: journalismDetails
	},



]

const router = new VueRouter({
	routes
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}


export default router
