<template>
	<div>
		<!-- 顶部定位及注册登录部分 -->
		<Header />
		<!-- 热了吗舒适家暖商城 -->

		<div class="title">
			<div class="titleLeft" @click="toRlm">
				<div class="titleLeftNav"><b>热了吗</b><b><span>·</span>舒适家暖居商城</b></div>

			</div>
			<div class="titleRightAll">
				<div class="titleRight " @click="returnHome">
					<div>网站首页</div>
					<div class="lineView"></div>

				</div>
				<div class="titleRight" @click="solution">
					<div>解决方案</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="industrialPark">
					<div>数字产业园</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="investmentPromotion">
					<div>招商加盟</div>
					<div class="lineView" ></div>
				</div>
				<div class="titleRight" @click="journalism">
					<div>新闻中心</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="helpCenter">
					<div>帮助中心</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="aboutWe">
					<div>关于零工儿</div>
					<div class="lineView"></div>
				</div>
			</div>
		</div>

		<!-- bannerView
		<img src="../assets/secondaryPage/pageBanner.png" class="banner"> -->

		<div class="bannerView" style="margin-top: 15px;">
			<div class="bannerView-titleView">居家生活便民服务</div>
			<div class="bannerView-contentView">
				安装、维修、搬运、保养、清洁、护理<br />
				就找上门儿小程序
			</div>
			<img src="../assets/secondaryPage/qrCode.jpg">
		</div>

		<div class="completeServiceProducts">
			<div class="completeServiceProducts-titleView">服务类品齐全</div>
			<div class="completeServiceProducts-contentView">
				<div class="completeServiceProducts-contentView-itemView">
					<img src="../assets/secondaryPage/personSmallIconOne.png">
					<div>便民服务</div>
					<div>代办跑腿、上门回收、摄影 整理收纳、健身瑜伽</div>
				</div>

				<div class="completeServiceProducts-contentView-itemView">
					<img src="../assets/secondaryPage/personSmallIconTwo.png">
					<div>保洁</div>
					<div>日常保洁、家具保养、空气治理 家具家电清洁、除虫除螨</div>
				</div>

				<div class="completeServiceProducts-contentView-itemView">
					<img src="../assets/secondaryPage/personSmallIconThree.png">
					<div>保姆月嫂</div>
					<div>保姆月嫂、催乳师、育儿早教 孕产健康、月子会所</div>
				</div>

				<div class="completeServiceProducts-contentView-itemView">
					<img src="../assets/secondaryPage/personSmallIconFour.png">
					<div>搬家运货</div>
					<div>家庭/企业的大中小搬</div>
				</div>

				<div class="completeServiceProducts-contentView-itemView">
					<img src="../assets/secondaryPage/personSmallIconFive.png">
					<div>房屋维修</div>
					<div>水电维修、门窗家具、厨卫洁具 管道疏通、防水补漏、环保除味 房屋主体维修、拆旧清运</div>
				</div>

				<div class="completeServiceProducts-contentView-itemView">
					<img src="../assets/secondaryPage/personSmallIconSix.png">
					<div>家电维修、清洗</div>
					<div>大家电、厨卫家电、生活家电</div>
				</div>


				<div class="completeServiceProducts-contentView-itemView">
					<img src="../assets/secondaryPage/personSmallIconSeven.png">
					<div>上门安装</div>
					<div>水电、家电、厨卫、灯具 门窗家具、窗帘五金、饰品 墙地面、办公</div>
				</div>

				<div class="completeServiceProducts-contentView-itemView">
					<img src="../assets/secondaryPage/personSmallIconEight.png">
					<div>美食厨师</div>
					<div>家常小厨、特色私厨</div>
				</div>
				<div class="completeServiceProducts-contentView-itemView">
					<img src="../assets/secondaryPage/personSmallIconNight.png" class="personSmallIconNight">
					<div>化妆护理</div>
					<div>上门美甲、手足护理、化妆 美发、美睫</div>
				</div>
				<div class="completeServiceProducts-contentView-itemView">
					<img src="../assets/secondaryPage/personSmallIconTen.png">
					<div>家庭洗护</div>
					<div>洗鞋、洗衣、洗家纺 奢侈品养护</div>
				</div>

			</div>
		</div>

		<div class="serviceMode">
			<div class="serviceMode-titleView">
				服务模式
				<img src="../assets/secondaryPage/pageBannerTwo.png">
			</div>

		</div>

		<div class="orderProcessView">
			<div class="orderProcessView-titleView">下单流程</div>
			<div class="orderProcessView-contentView">
				<div class="orderProcessView-contentView-leftView">
					<div class="orderProcessView-contentView-leftView-itemViewTwo " :class="{colorGreen:colorGreenOne}"
						@click="releaseOrderClick">
						<img :src="orderProcessSmallIconOne">
						<div>
							<div :class="{colorWhite:colorGreenOne}">发布订单</div>
							<div :class="{colorWhite:colorGreenOne}">简单几步，快速下单</div>
						</div>

					</div>



					<div class="orderProcessView-contentView-leftView-itemViewTwo " :class="{colorGreen:colorGreenTwo}"
						@click="releaseOrderClickTwo">
						<img :src="orderProcessSmallIconTwo">
						<div>
							<div :class="{colorWhite:colorGreenTwo}">工人报价</div>
							<div :class="{colorWhite:colorGreenTwo}">通知周边工人 多位工人报价</div>
						</div>
					</div>


					<div class="orderProcessView-contentView-leftView-itemViewTwo "
						:class="{colorGreen:colorGreenThree}" @click="releaseOrderClickThree">

						<img :src="orderProcessSmallIconThree">
						<div>
							<div :class="{colorWhite:colorGreenThree}">选择工人</div>
							<div :class="{colorWhite:colorGreenThree}">价格合理 服务到位的工人</div>
						</div>
					</div>




					<div class="orderProcessView-contentView-leftView-itemViewTwo " :class="{colorGreen:colorGreenFour}"
						@click="releaseOrderClickFour">
						<img :src="orderProcessSmallIconFour">
						<div>
							<div :class="{colorWhite:colorGreenFour}">上门服务</div>
							<div :class="{colorWhite:colorGreenFour}">确认预约时间 准时上门</div>
						</div>
					</div>


					<div class="orderProcessView-contentView-leftView-itemViewTwo " :class="{colorGreen:colorGreenFive}"
						@click="releaseOrderClickFive">
						<img :src="orderProcessSmallIconFive">
						<div>
							<div :class="{colorWhite:colorGreenFive}">验收评价</div>
							<div :class="{colorWhite:colorGreenFive}">通知周边工人 多位工人报价</div>
						</div>
					</div>




				</div>
				<div class="orderProcessView-contentView-centerView">

					<div class="orderProcessView-contentView-centerView-imgView">
						<img src="../assets/secondaryPage/orderProcessSecondaryIconOne.png">
						<img src="../assets/secondaryPage/orderProcessSecondaryIconTwo.png">
						<img src="../assets/secondaryPage/orderProcessSecondaryIconThree.png">
						<img src="../assets/secondaryPage/orderProcessSecondaryIconFour.png">
						<img src="../assets/secondaryPage/orderProcessSecondaryIconFive.png">
					</div>
					<div class="orderProcessView-contentView-centerView-contentView">
						<div class="orderProcessView-contentView-centerView-contentView-oneView" v-if="colorGreenOne">
							发布订单</div>
						<div class="orderProcessView-contentView-centerView-contentView-twoView" v-if="colorGreenOne">
							简单几步&nbsp;&nbsp;&nbsp;&nbsp;快速下单</div>

						<div class="orderProcessView-contentView-centerView-contentView-oneView" v-if="colorGreenTwo">
							工人报价</div>
						<div class="orderProcessView-contentView-centerView-contentView-twoView" v-if="colorGreenTwo">
							通知周边工人 多位工人报价</div>

						<div class="orderProcessView-contentView-centerView-contentView-oneView" v-if="colorGreenThree">
							选择工人</div>
						<div class="orderProcessView-contentView-centerView-contentView-twoView" v-if="colorGreenThree">
							价格合理 服务到位的工人</div>
						<div class="orderProcessView-contentView-centerView-contentView-oneView" v-if="colorGreenFour">
							上门服务</div>
						<div class="orderProcessView-contentView-centerView-contentView-twoView" v-if="colorGreenFour">
							确认预约时间 准时上门</div>
						<div class="orderProcessView-contentView-centerView-contentView-oneView" v-if="colorGreenFive">
							验收评价</div>
						<div class="orderProcessView-contentView-centerView-contentView-twoView" v-if="colorGreenFive">
							通知周边工人 多位工人报价</div>

					</div>


				</div>
				<div class="orderProcessView-contentView-rightView">
					<img src="../assets/secondaryPage/orderProcessBigIcon.png">
				</div>
			</div>
		</div>

		<div class="serviceGuaranteeView">
			<div class="serviceGuaranteeView-titleView">
				服务保障
			</div>
			<div class="serviceGuaranteeView-contentView">
				<div class="serviceGuaranteeView-contentView-sonView">
					<div class="serviceGuaranteeView-contentView-itemView">
						<img src="../assets/secondaryPage/serviceGuaranteeOne.png">
						<div class="serviceGuaranteeView-contentView-itemView-contentView">
							<div>专业师傅，认证上岗</div>
							<div>工人实名认证，职业技能资质认证 专业服务有保障</div>
						</div>
					</div>

					<div class="serviceGuaranteeView-contentView-itemView">
						<img src="../assets/secondaryPage/ServiceGuaranteeTwo.png">
						<div class="serviceGuaranteeView-contentView-itemView-contentView">
							<div>专业师傅，认证上岗</div>
							<div>工人实名认证，职业技能资质认证 专业服务有保障</div>
						</div>
					</div>



					<div class="serviceGuaranteeView-contentView-itemView">
						<img src="../assets/secondaryPage/ServiceGuaranteeThree.png">
						<div class="serviceGuaranteeView-contentView-itemView-contentView">
							<div>专业师傅，认证上岗</div>
							<div>工人实名认证，职业技能资质认证 专业服务有保障</div>
						</div>
					</div>


					<div class="serviceGuaranteeView-contentView-itemView">
						<img src="../assets/secondaryPage/ServiceGuaranteeFour.png">
						<div class="serviceGuaranteeView-contentView-itemView-contentView">
							<div>专业师傅，认证上岗</div>
							<div>工人实名认证，职业技能资质认证 专业服务有保障</div>
						</div>
					</div>
				</div>

			</div>

		</div>





		<Footer></Footer>
	</div>
</template>

<script>
	import Header from "../components/header";
	import Footer from "../components/footer";
	import Config from "../request/config.js";
	import {

	} from "../request/api.js";
	export default {
		name: "index",
		data() {
			return {
				baseImgUrl: Config.baseImgUrl,
				play: false,
				value: "",
				colorGreenOne: true,

				colorGreenTwo: false,
				colorGreenThree: false,
				colorGreenFour: false,
				colorGreenFive: false,

				orderProcessSmallIconOne: require("../assets/secondaryPage/orderProcessSmallIconOneWhite.png"),
				orderProcessSmallIconTwo: require("../assets/secondaryPage/orderProcessSmallIconTwo.png"),
				orderProcessSmallIconThree: require("../assets/secondaryPage/orderProcessSmallIconThree.png"),
				orderProcessSmallIconFour: require("../assets/secondaryPage/orderProcessSmallIconFour.png"),
				orderProcessSmallIconFive: require("../assets/secondaryPage/orderProcessSmallIconFive.png"),



			};
		},
		created() {
			window.scrollTo(0, 0);
		},
		components: {
			Footer,
			Header
		},
		mounted() {
			// 切换页面时滚动条自动滚动到顶部
			window.scrollTo(0, 0);
		},
		methods: {

			releaseOrderClick() {
				this.colorGreenOne = true
				this.colorGreenTwo = false
				this.colorGreenThree = false
				this.colorGreenFour = false
				this.colorGreenFive = false

				this.orderProcessSmallIconOne = require("../assets/secondaryPage/orderProcessSmallIconOneWhite.png")
				this.orderProcessSmallIconTwo = require("../assets/secondaryPage/orderProcessSmallIconTwo.png")
				this.orderProcessSmallIconThree = require("../assets/secondaryPage/orderProcessSmallIconThree.png")
				this.orderProcessSmallIconFour = require("../assets/secondaryPage/orderProcessSmallIconFour.png")
				this.orderProcessSmallIconFive = require("../assets/secondaryPage/orderProcessSmallIconFive.png")
			},
			releaseOrderClickTwo() {
				this.colorGreenOne = false
				this.colorGreenTwo = true
				this.colorGreenThree = false
				this.colorGreenFour = false
				this.colorGreenFive = false

				this.orderProcessSmallIconOne = require("../assets/secondaryPage/orderProcessSmallIconOne.png")
				this.orderProcessSmallIconTwo = require("../assets/secondaryPage/orderProcessSmallIconTwoWhite.png")
				this.orderProcessSmallIconThree = require("../assets/secondaryPage/orderProcessSmallIconThree.png")
				this.orderProcessSmallIconFour = require("../assets/secondaryPage/orderProcessSmallIconFour.png")
				this.orderProcessSmallIconFive = require("../assets/secondaryPage/orderProcessSmallIconFive.png")
			},
			releaseOrderClickThree() {
				this.colorGreenOne = false
				this.colorGreenTwo = false
				this.colorGreenThree = true
				this.colorGreenFour = false
				this.colorGreenFive = false
				this.orderProcessSmallIconOne = require("../assets/secondaryPage/orderProcessSmallIconOne.png")
				this.orderProcessSmallIconTwo = require("../assets/secondaryPage/orderProcessSmallIconTwo.png")
				this.orderProcessSmallIconThree = require("../assets/secondaryPage/orderProcessSmallIconThreeWhite.png")
				this.orderProcessSmallIconFour = require("../assets/secondaryPage/orderProcessSmallIconFour.png")
				this.orderProcessSmallIconFive = require("../assets/secondaryPage/orderProcessSmallIconFive.png")
			},
			releaseOrderClickFour() {
				this.colorGreenOne = false
				this.colorGreenTwo = false
				this.colorGreenThree = false
				this.colorGreenFour = true
				this.colorGreenFive = false
				this.orderProcessSmallIconOne = require("../assets/secondaryPage/orderProcessSmallIconOne.png")
				this.orderProcessSmallIconTwo = require("../assets/secondaryPage/orderProcessSmallIconTwo.png")
				this.orderProcessSmallIconThree = require("../assets/secondaryPage/orderProcessSmallIconThree.png")
				this.orderProcessSmallIconFour = require("../assets/secondaryPage/orderProcessSmallIconFourWhite.png")
				this.orderProcessSmallIconFive = require("../assets/secondaryPage/orderProcessSmallIconFive.png")
			},
			releaseOrderClickFive() {
				this.colorGreenOne = false
				this.colorGreenTwo = false
				this.colorGreenThree = false
				this.colorGreenFour = false
				this.colorGreenFive = true
				this.orderProcessSmallIconOne = require("../assets/secondaryPage/orderProcessSmallIconOne.png")
				this.orderProcessSmallIconTwo = require("../assets/secondaryPage/orderProcessSmallIconTwo.png")
				this.orderProcessSmallIconThree = require("../assets/secondaryPage/orderProcessSmallIconThree.png")
				this.orderProcessSmallIconFour = require("../assets/secondaryPage/orderProcessSmallIconFour.png")
				this.orderProcessSmallIconFive = require("../assets/secondaryPage/orderProcessSmallIconFiveWhite.png")
			},

			aboutWe() {
				this.$router.push({
					name: 'PlatformIntroduction',
					params: {
						content: '1',
					}
				})
				localStorage.setItem("palt","1")
			},
			solution() {
				this.$router.push({
					name: 'solution'
				})
			},
			returnHome() {
				this.$router.push({
					name: 'index',
					params: {
						content: '1',
					}
				})
			},
			investmentPromotion() {
				this.$router.push({
					name: 'investmentPromotion',
					params: {
						content: '1',
					}
				})
			},
			journalism() {
				this.$router.push({
					name: 'journalism',

				})
			},
			industrialPark() {
				this.$router.push({
					name: 'industrialPark',

				})
			},
			helpCenter() {
				this.$router.push({
					name: 'helpcenter',

				})
			},
			oddJobClick() {
				this.$router.push({
					name: 'PlatformIntroduction',
					params: {
						content: '2',
					}
				})
			},
			toRlm() {
				window.open("http://www.areyouhot.cn");
			},
		},
	};
</script>
<style>
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: textfield;
	}

	input[type="number"] {
		-moz-appearance: textfield;
	}

	/* 如果以上代码不起作用就用  */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none !important;
	}

	input[type="number"] {
		-moz-appearance: textfield;
	}
</style>

<style lang="scss" scoped>
	::v-deep.el-carousel__container {
		position: relative;
		height: 650px;
	}

	$font-color: #000000;
	$light-color: #f76a00;
	$white: #ffffff;

	//********标题********

	.title {
		width: 1280px;
		
		box-sizing: border-box;
		min-width: 1280px;
		
		margin: 0 auto;
		height: 35px;
		position: relative;
		z-index: 999;
		display: flex;
		justify-content: space-between;
		margin-bottom: 5px;


		.titleLeft {
			box-sizing: border-box;
			// width: 240px;
			// width: 360px;
			color: $white;
			float: left;
			// overflow: hidden;


			.titleLeftNav {
				// font-size: 15px;
				font-size: 15px;
				// line-height: 30px;
				text-align: center;
				// border-bottom: 5px solid #ff8041;
				// box-sizing: border-box;
				width: 344px;
				height: 43px;
				background: #ff8041;
				padding-bottom: 5px;
				font-weight: bold;
				color: #ffffff;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				// padding-bottom: 5px;


				b:nth-of-type(1) {
					font-size: 16px;
					font-weight: 700;


				}


				b:nth-of-type(2) {
					font-weight: 400;

					span {
						padding: 0 10px;
					}


				}
			}

			.bigImgLeft {
				overflow: hidden;
				height: 99%;
				z-index: 999;
				position: absolute;
				height: 653px;
				// width: 240px;
				width: 346px;
				background: #394144;
				box-sizing: border-box;
				padding: 0 31px;
				opacity: 0.9;

				// overflow-y: scroll;

				.bigImgLeftItem {
					width: 100%;
					margin: 0 auto;
					// border-bottom: 1px solid rgba($color: $white, $alpha: 0.3);
					color: $white;
					// padding-bottom: 20px;
					// padding: 30px 0;
					box-sizing: border-box;
					margin-top: 20px;
					// height:126px;
					// background-color: red;


					.bigImgLeftItemTitle {
						font-weight: bold;
						box-sizing: border-box;
						cursor: pointer;
						// padding: 20px 0 15px;
						display: flex;
						flex-direction: row;
						justify-content: flex-start;
						align-items: center;
						padding-top: 30px;
						box-sizing: border-box;
						margin-bottom: 14px;


						.bigImgLeftItemTitleText {
							padding-left: 15px;
							// font-size: 14px;
							font-size: 19px;
							border-left: 1px solid #ffffff;
							color: #fff;


						}

						.bigImgLeftItemTitleText:hover {
							color: #fa7c25;
						}
					}

					.bigImgLeftItemInfo {
						// font-size: 14px;
						font-size: 16px;
						line-height: 16px;
						cursor: pointer;
						color: rgba($color: #fff, $alpha: 0.7);



						.bigImgLeftItemInfoInner {
							display: inline-block;
							padding: 8px 0;



						}

						.line {
							padding: 0 10px;
						}

						.bigImgLeftItemInfoInner:hover {
							color: #fa7c25;
						}
					}
				}
			}

			img {
				width: 20px;
				height: 20px;
				vertical-align: middle;
				margin-right: 5px;
				padding-left: 20px;
			}
		}

		.titleLeft:hover {
			cursor: pointer;
		}

		.titleRightAll {
			display: flex;


			.titleRight {
				width: 100px;
				line-height: 30px;
				font-size: 17px;
				text-align: center;
				color: #333333;
				margin-left: 20px;
				cursor: pointer;
				padding-bottom: 2px;
				padding-top: 2px;

				.lineView {
					// border-bottom-left-radius: 3px !important;
					// border-bottom-right-radius: 3px !important;
					// border-bottom: 5px solid #ebeaea;
					width: 100%;
					height: 5px;
					background-color: #ebeaea;
					border-radius: 10px;
					margin-top: 5px;

					&.active,
					&:hover {
						width: 100%;
						height: 5px;
						background-color: #ff8041;
						border-radius: 10px;

						// border-bottom-color: #ff8041;
						// border-bottom-left-radius: 3px !important;
						// border-bottom-right-radius: 3px !important;
					}
				}




			}

			// .titleRight+.titleRight {
			// 	&::before {
			// 		content: "";
			// 		position: absolute;
			// 		top: 50%;
			// 		left: 0;
			// 		margin-top: -9px;
			// 		width: 2px;
			// 		height: 18px;
			// 		background-color: #e2e0e0;
			// 	}
			// }
		}
	}


	// ******公告******
	.list {
		list-style: none;
		text-align: center;
		overflow: hidden;
		height: 40px;
		padding-top: 2px;

		li {
			text-align: left;
			height: 40px;
			line-height: 40px;
			color: #565656;
		}

		.toUp {
			margin-top: -40px;
			transition: all 0.5s;
		}
	}

	.btn-primary {
		background-color: #105697;
		border-color: #105697;
		border-radius: 10px;
	}

	.btn-orange {
		background-color: #ff8041;
		border-color: #ff8041;
		border-radius: 10px;
	}

	.platPointer {
		cursor: pointer;
	}

	::v-deep .el-carousel--horizontal {
		overflow-x: visible;
	}

	// .banner {
	// 	width: 100%;
	// 	height: 460px;
	// }

	.completeServiceProducts {
		margin-top: 62px;
		height: 642px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;

	}

	.completeServiceProducts-titleView {
		font-size: 26px;
		font-weight: 400;
		color: #293038;
		margin-bottom: 54px;

	}

	.completeServiceProducts-contentView {
		// width: 1440px;
		// width: 1380px;
		// width: 1200px;
		width:1280px;
		height: 555px;
		margin: 0 auto;
		// background-color: yellow;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
	}

	.completeServiceProducts-contentView-itemView {
		width: 264px;
		height: 262px;
		background: #ffffff;
		border: 1px solid #dfdfdf;
		border-radius: 20px;
		padding: 22px 0 29px 0;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;

	}

	.completeServiceProducts-contentView-itemView img {
		width: 40px;
		height: 113px;
	}

	.completeServiceProducts-contentView-itemView div:nth-child(2) {
		font-size: 18px;
		font-weight: 400;
		text-align: LEFT;
		color: #333333;
		margin: 14px 0 7px 0;

	}

	.completeServiceProducts-contentView-itemView div:nth-child(3) {
		width: 194px;
		height: 53px;
		font-size: 14px;

		font-weight: 300;
		text-align: CENTER;
		color: #666666;
	}

	.personSmallIconNight {
		width: 114px !important;
		height: 114px !important;
	}

	.serviceMode {
		margin-top: 63px;
		height: 610px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
	}

	.serviceMode-titleView {
		font-size: 26px;
		font-weight: 400;
		text-align: center;
		color: #293038;
	}

	.serviceMode img {
		width: 100%;
		height: 528px;
		margin-top: 49px;
	}

	.orderProcessView {
		height: 603px;
		width: 1435px;
		margin: 0 auto;
	}

	.orderProcessView-titleView {
		font-size: 26px;

		font-weight: 400;
		text-align: center;
		color: #293038;
		margin-top: 62px;
		margin-bottom: 64px;
	}

	.orderProcessView-contentView {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
	}

	.orderProcessView-contentView-leftView {
		width: 255px;
		height: 469px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		background-color: #f6f6f6;



	}

	.orderProcessView-contentView-leftView-itemView {
		width: 255px;
		height: 90px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		padding-left: 26px;
		box-sizing: border-box;

		background-color: #f6f6f6;
		border-bottom: 1px solid #e7e7e7;
	}

	.colorGreen {
		background: linear-gradient(90deg, #1180e7 0%, #3ea2ff 100%);
	}

	.orderProcessView-contentView-leftView-itemViewTwo {
		width: 255px;
		height: 90px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		padding-left: 26px;
		box-sizing: border-box;
		background-color: #f6f6f6;
		border-bottom: 1px solid #e7e7e7;
	}

	.orderProcessView-contentView-leftView-itemView img,
	.orderProcessView-contentView-leftView-itemViewTwo img {
		margin-right: 20px;
		height: 27px;
		width: 25px;
	}

	.orderProcessView-contentView-leftView-itemView div div:nth-child(1),
	.orderProcessView-contentView-leftView-itemViewTwo div div:nth-child(1) {

		font-size: 16px;
		font-weight: 400;
		text-align: LEFT;
		color: #FFFFFF;
		margin-bottom: 6px;

	}

	.orderProcessView-contentView-leftView-itemView div div:nth-child(2),
	.orderProcessView-contentView-leftView-itemViewTwo div div:nth-child(1) {

		font-size: 13px;
		font-weight: 400;
		text-align: LEFT;
		color: #FFFFFF;
	}

	.orderProcessView-contentView-leftView-itemViewTwo div div:nth-child(1) {
		color: #333;
	}

	.orderProcessView-contentView-leftView-itemViewTwo div div:nth-child(2) {
		color: #666;
	}

	.orderProcessView-contentView-leftViewItemViewThree div div:nth-child(1) {
		color: #333;
	}

	.orderProcessView-contentView-leftView-itemViewTwo div div:nth-child(2) {
		color: #666;
	}

	.orderProcessView-contentView-centerView {
		width: 914px;
		height: 100%;
		padding-left: 147px;
		padding-right: 109px;
		padding-top: 74px;
		padding-bottom: 139px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;

	}

	.orderProcessView-contentView-centerView-imgView {
		width: 100%;
		height: 110px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.orderProcessView-contentView-centerView-imgView img {
		width: 110px;
		height: 110px;
	}

	.orderProcessView-contentView-centerView-contentView {
		margin-top: 65px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	}

	.orderProcessView-contentView-centerView-contentView-oneView {

		font-size: 26px;
		font-weight: 400;
		text-align: LEFT;
		color: #333333;
	}

	.orderProcessView-contentView-centerView-contentView-twoView {

		font-size: 24px;
		font-weight: 400;
		text-align: LEFT;
		color: #666666;
		margin-top: 15px;
	}

	.orderProcessView-contentView-rightView {}

	.orderProcessView-contentView-rightView img {
		width: 266px;
		height: 507px;
	}

	.serviceGuaranteeView {
		margin-top: 28px;
		height: 516px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
	}

	.serviceGuaranteeView-titleView {
		font-size: 26px;
		font-weight: 400;

		color: #293038;
	}

	.serviceGuaranteeView-contentView {
		width: 100%;
		height: 433px;
		background-image: url(../assets/secondaryPage/bottomBigImg.png);
		background-size: 100% 100%;


	}

	.serviceGuaranteeView-contentView-sonView {
		// width: 1440px;
		// width: 1380px;
		// width: 1200px;
		width:1280px;
		height: 433px;
		padding: 72px 0 72px 0;
		box-sizing: border-box;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		box-sizing: border-box;
		margin: 0 auto;
	}

	.serviceGuaranteeView-contentView-itemView {
		width: 697px;
		height: 130px;
		background: #ffffff;
		border-radius: 10px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 29px 110px 40px 66px;
		box-sizing: border-box;
	}

	.serviceGuaranteeView-contentView-itemView img {
		width: 59px;
		height: 59px;
	}

	.serviceGuaranteeView-contentView-itemView-contentView div:nth-child(1) {
		font-size: 20px;
		font-weight: 400;
		color: #333333;
		margin-bottom: 12px;
	}

	.serviceGuaranteeView-contentView-itemView-contentView div:nth-child(2) {
		font-size: 16px;
		font-weight: 400;
		color: #999999;
	}

	.bannerView {
		width: 100%;
		height: 460px;
		background-image: url(../assets/secondaryPage/pageBanner.png);
		background-size: 100% 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		padding-top: 102px;
		padding-bottom: 96px;
		box-sizing: border-box;
		

	}

	.bannerView-titleView {

		font-size: 30px;
		font-weight: 500;
		text-align: LEFT;
		color: #333333;
	}

	.bannerView-contentView {
		width: 306px;
		// height: 25px;
		font-size: 18px;
		font-weight: 500;
		text-align: LEFT;
		color: #333333;
		margin-top: 30px;

		text-align: center;
	}

	.bannerView img {
		width: 120px;
		height: 120px;
		margin-top: 44px;
	}

	.colorWhite {
		color: #fff !important
	}

	// <img src="../assets/secondaryPage/pageBanner.png" class="banner">{}
</style>
