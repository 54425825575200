<template>
	<div style="background: #fff">
		<div class="nav">
			<div class="navList">
				<div class="navListMain">
					<div class="navListMainLeft">
						<div class="navListMainLeftTop">
							<img src="../assets/icon/location.png" />
							<span class="navListMainLeftNow">{{ defaultCity }}</span>
							<span class="navListMainLeftChange">切换城市</span>
						</div>
						<div ref="citylist" class="cityList">
							<div class="cityListAll">
								<div class="cityListAllTop">
									<div class="cityListAllTopTitle">猜你想去</div>
									<div class="cityListAllTopInfo">
										<div class="cityListAllTopInfoItem" v-for="(item, index) in cityListOne"
											:key="index" @click="chooseCityOne(index)">
											{{ item }}
										</div>
									</div>
								</div>
								<div class="cityListAllTop">
									<div class="cityListAllTopTitle">热门城市</div>
									<div class="cityListAllTopInfo">
										<div class="cityListAllTopInfoItem" v-for="(item, index) in cityListTwo"
											:key="index" @click="chooseCityTwo(index)">
											{{ item }}
										</div>
									</div>
								</div>
								<div class="cityListAllBot">
									<div class="cityListAllBotItem">全部城市 ></div>
								</div>
							</div>
						</div>
					</div>
					<div class="navListMainRight">
						<div class="navListMainRightLogin" v-if="isShowLogin" @click="toLogin">
							您好，请登录
						</div>
						<div class="navListMainRightLoginOut" v-if="!isShowLogin">
							{{ mobile }}&nbsp;&nbsp;&nbsp;&nbsp;
							<span>退出</span>
						</div>
						<div class="navListMainRightRegister" v-if="isShowLogin" @click="toRegister">
							免费注册
						</div>
						<!-- <div class="navListMainRightJoin" @click="toEnterprise">
                            企业入驻
                        </div> -->
						<div class="navListMainRightApp">
							零工儿app
							<div class="navListMainRightAppTitle">
								<img src="../assets/icon/zhankai.png" />
							</div>
							<div class="navListMainRightHover">
								<div class="navListMainRightHoverList">
									<div class="navListMainRightHoverListItem">
										<div class="navListMainRightHoverListItemIcon">
											<img src="../assets/images/oddjobApp.png" />
										</div>
										<div class="navListMainRightHoverListItemText">
											<p>零工儿工人端App</p>
											<p>随时随地找事儿做</p>
											<span @click="toAppUpload">接单赚钱</span>
										</div>
									</div>
									<div class="navListMainRightHoverListItemGap"></div>
									<div class="navListMainRightHoverListItem">
										<div class="navListMainRightHoverListItemIcon">
											<img src="../assets/images/pop2.jpg" />
										</div>
										<div class="navListMainRightHoverListItemText">
											<p>零工儿微信公众号</p>
											<p>随手掌握零工儿一手资讯</p>
										</div>
									</div>
									<!-- <div class="navListMainRightHoverListItemGap"></div> -->
									<!-- <div class="navListMainRightHoverListItem">
										<div class="navListMainRightHoverListItemIcon">
											<img src="../assets/images/pop3.png" />
										</div>
										<div class="navListMainRightHoverListItemText">
											<p>零工儿小程序</p>
											<p>微信扫一扫，需求发布更轻盈</p>
										</div>
									</div> -->
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="navTop" v-if="$route.params.path!='PlatformIntroduction'">
			<div class="navTopMain">
				<div class="navTopLogo" @click="reLoad">
					<img src="../assets/images/logo_xinTwo.png" alt srcset />
				</div>
				<div class="chengDiv">
					<div class="navTopInput">
						<!-- <el-input placeholder="搜索关键词" prefix-icon="el-icon-search" v-model="search"></el-input> -->
						<!-- 灵活用工&nbsp;&nbsp;&nbsp;&nbsp;行业首选 -->
						智慧就业 &nbsp;&nbsp;&nbsp;&nbsp;真实用工 &nbsp;&nbsp;&nbsp;&nbsp;行业首选
					</div>
					<div class="navTopPhone">
						<div class="navTopPhone-title">全国免费咨询热线</div>
						<div class="navTopPhone-div">
							<img src="../assets/images/phone24.png" alt />
							<div class="navTopPhoneText">
								<p>400-024-0086</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "VueHeader",
		data() {
			return {
				defaultCity: "沈阳",
				isShowLogin: true,
				cityListOne: ["沈阳", "盘锦", "深圳", "上海", "广州", "重庆"],
				cityListTwo: [
					"沈阳",
					"盘锦",
					"深圳",
					"上海",
					"广州",
					"重庆",
					"成都",
					"杭州",
					"郑州",
					"武汉",
					"南京",
					"西安",
				],
				search: "",
				moduleIndex: true,
			};
		},
		created() {
			console.log(this.$route.params.path)
		},
		mounted() {},
		methods: {
			chooseCityOne(index) {
				this.defaultCity = this.cityListOne[index];
			},
			chooseCityTwo(index) {
				this.defaultCity = this.cityListTwo[index];
			},
			toLogin() {
				window.location.href = "https://seller.odd-job.net/page/login/index.html";
			},
			toRegister() {
				window.location.href = "https://seller.odd-job.net/page/login/index.html";
			},
			joinEnterprise() {
				this.$router.push({
					path: "/enterprise",
					name: "enterprise",
				});
			},
			reLoad() {
				// location.reload();
				this.$router.push({
					path: "/index",
					name: "index",
				});
			},
			toEnterprise() {
				this.$router.push({
					path: "/enterprise",
					name: "enterprise",
				});
			},
			toIndex() {
				this.$router.push({
					path: "/index",
					name: "index",
				});
			},
			toAppUpload() {
				window.open("http://www.odd-job.net/app/");
			},
		},
	};
</script>

<style lang="scss" scoped>
	ul li {
		list-style: none;
	}

	a {
		text-decoration-line: none;
		color: #333;
	}

	/* 头部 */
	.nav {
		margin: 0;
		padding: 0;
		min-width: 1280px;
		// min-width: 1200px;
	}

	.navImg {
		display: block;
		width: 100%;
		min-width: 1280px;
		// min-width: 1200px;
		min-height: 60px;
		overflow: hidden;
		height: 60px;
		background-color: #fbfbfb;
		border-bottom: 1px solid #eeeeee;
	}

	.navImg img {
		height: 60px;
		width: 100%;
	}

	.navList {
		height: 38px;
		width: 100%;
		background-color: #fbfbfb;
		border-bottom: 1px solid #eeeeee;
	}

	.navListMain {
		margin: 0 auto;
		width: 1280px;
		min-width: 1280px;
		// width: 1200px;
		// min-width: 1200px;
		display: flex;
		display: -ms-flexbox;
		justify-content: space-between;
		-ms-flex-pack: justify;
	}

	.cityList {
		display: none;
		z-index: 1000;
		width: 410px;
		position: absolute;
		background-color: #ffffff;
		border: 1px solid #eeeeee;
	}

	.cityListAll {
		margin: 0 auto;
		width: 90%;
	}

	.cityListAllTop {
		width: 369px;
		font-size: 12px;
		padding-top: 10px;
		border-bottom: 1px solid #eeeeee;
	}

	.cityListAllTopTitle {
		color: #888888;
		cursor: default;
		width: 369px;
	}

	.cityListAllTopInfo {
		display: flex;
		display: -ms-flexbox;
		flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		color: #333333;
		padding: 10px 0;
	}

	.cityListAllTopInfoItem {
		padding: 5px;
		cursor: pointer;
		color: #333333;
	}

	.cityListAllTopInfoItem:hover {
		color: #ff430d;
	}

	.cityListAllBot {
		width: 100%;
	}

	.cityListAllBotItem {
		float: right;
		color: #888888;
		font-size: 12px;
		text-align: right;
		padding: 10px 0;
		cursor: pointer;
	}

	.cityListAllBotItem:hover {
		color: #ff430d;
	}

	.cityListAllBotItem img {
		width: 14px;
		height: 14px;
		vertical-align: middle;
		margin-left: 2px;
	}

	.navListMainLeft {
		font-size: 12px;
		// float: left;
	}

	.navListMainLeftTop {
		height: 38px;
		line-height: 38px;
		cursor: pointer;
	}

	.navListMainLeft:hover .cityList {
		display: block;
	}

	.navListMainLeft:hover .navListMainLeftChange {
		color: #105697;
	}

	.navListMainLeft img {
		width: 11px;
		height: 15px;
		vertical-align: text-bottom;
	}

	.navListMainLeftNow {
		margin-left: 7px;
		color: #105697;
	}

	.navListMainLeftChange {
		margin-left: 16px;
		padding: 0 16px;
		color: #bbb9b9;
		background-color: #fff;
		border: 1px solid #eaeaea;
		border-radius: 50px;
		cursor: pointer;
	}

	.navListMainRight {
		// float: right;
		position: relative;
		font-size: 12px;
		color: #888888;
	}

	.navListMainRight img {
		width: 9px;
		height: 6px;
	}

	.navListMainRightLoginOut {
		display: inline-block;
		color: #888888;
		height: 38px;
		line-height: 38px;
		padding: 0 20px;
	}

	.navListMainRightLoginOut span {
		cursor: pointer;
	}

	.navListMainRightLoginOut span:hover {
		color: #2d6ada;
	}

	.navListMainRightMylg img {
		width: 9px;
		height: 6px;
	}

	.navListMainRightLogin {
		display: inline;
		color: #888888;
		cursor: pointer;
		padding: 0 20px;
	}

	.navListMainRightLogin:hover {
		color: #2d6ada;
	}

	.navListMainRightRegister {
		display: inline-block;
		color: #ff8041;
		cursor: pointer;
		height: 38px;
		line-height: 38px;
		padding: 0 20px;
	}

	.navListMainRightMylg {
		display: inline;
		color: #888888;
		cursor: pointer;
		height: 38px;
		line-height: 38px;
	}

	.navListMainRightMylg:hover {
		color: #fa7c25;
	}

	.navListMainRightJoin {
		display: inline-block;
		color: #000000;
		cursor: pointer;
		padding: 0 20px;
		height: 38px;
		line-height: 38px;
	}

	.navListMainRightService {
		display: inline;
		color: #888888;
		cursor: pointer;
	}

	.navListMainRightService:hover {
		color: #fa7c25;
	}

	.navListMainRightApp {
		display: inline-block;
		color: #0c0707;
		cursor: pointer;
		height: 38px;
		line-height: 38px;
		margin-left: 20px;
		// padding: 0 20px;
		border-left: 1px solid transparent;
		border-right: 1px solid transparent;
	}

	.navListMainRightApp:hover {
		border-left: 1px solid #cfcfcf;
		border-right: 1px solid #cfcfcf;
		box-shadow: 0 2px 4px 0 #eeeeee;
		-ms-box-shadow: 0 2px 4px 0 #eeeeee;
	}

	.navListMainRightApp:hover .navListMainRightHover {
		display: block;
		-webkit-animation: a 0.5s;
		animation: a 0.5s;
	}

	.navListMainRightApp:hover .navListMainRightAppTitle img {
		transform: rotate(180deg);
		-webkit-transform: rotate(180deg);
		-moz-transform: rotate(180deg);
		-o-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
	}

	.navListMainRightHover {
		display: none;
		position: absolute;
		top: 38px;
		right: 2px;
		z-index: 1000;
		width: 285px;
		cursor: default;
	}

	.navListMainRightHoverList {
		background-color: #ffffff;
		width: 100%;
		border: 1px solid #cfcfcf;
		box-shadow: 0 2px 4px 0 #eeeeee;
	}

	.navListMainRightHoverListItem {
		width: 90%;
		height: 76px;
		margin: 20px auto;
		overflow: hidden;
	}

	.navListMainRightHoverListItemGap {
		width: 90%;
		margin: 0 auto;
		border-bottom: 1px dashed #cfcfcf;
	}

	.navListMainRightHoverListItemIcon {
		height: 76px;
	}

	.navListMainRightHoverListItemIcon img {
		width: 76px;
		height: 76px;
	}

	.navListMainRightHoverListItem div {
		float: left;
	}

	.navListMainRightHoverListItemText {
		color: #666666;
		font-size: 12px;
		line-height: 20px;
		margin-left: 10px;
	}

	.navListMainRightHoverListItemText p:first-child {
		font-weight: 600;
		font-size: 12px;
	}

	.navListMainRightHoverListItemText span {
		display: inline-block;
		color: #ffffff;
		padding: 4px 16px;
		background: #006ebd;
		border-radius: 4px;
		margin-top: 5px;
		cursor: pointer;
	}

	.navListMainRightHoverListItemText span:hover {
		background-color: #328aca;
		transition: all linear 0.3s;
		-moz-transition: all linear 0.3s;
		-webkit-transition: all linear 0.3s;
		-ms-transition: all linear 0.3s;
	}

	.navListMainRightAppTitle {
		display: inline-block;
	}

	/* logo部分 */
	.navTop {
		width: 1280px;
		min-width: 1280px;
		// width: 1200px;
		// min-width: 1200px;
		margin: 0 auto;
		background-color: #ffffff;
		// padding: 25px 0;
		overflow: hidden;
		box-sizing: border-box;
		display: flex;
		display: -ms-flexbox;
		justify-content: space-between;
		-ms-flex-pack: justify;
		padding: 59px 0 27px 0;



		.navTopMain {
			display: flex;
			display: -ms-flexbox;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-end;
			-ms-flex-pack: justify;
			width: 100%;
		}

		.navTopLogo {
			float: left;
			padding-left: 60px;
			cursor: pointer;

			img {
				// width: 198px;
				// height: 57px;
				width: 230px;
				height: 65px;
			}
		}

		.navTopInput {
			// width: 408px;

			// ::v-deep .el-input {
			// 	.el-input__inner {
			// 		border-radius: 18px;
			// 		height: 30px;
			// 		line-height: 30px;
			// 	}

			// 	.el-input__icon {
			// 		line-height: 30px;
			// 	}
			// }
			// font-size: 36px;
			// color: #FF8041
			// margin-right: 95px;
			// letter-spacing:14px;
			// font-size: 34px;
			font-size: 26px;

			font-weight: 400;
			text-align: LEFT;
			color: #ff8041;
			// line-height: 140px;
			letter-spacing: 10.2px;
			margin-top:30px;
			

		}

		.navTopPhone {
			overflow: hidden;
			padding: 20px 0;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
		
			align-items: flex-end;

			// width: 50%;

			.navTopPhone-title {
				// font-size: 18px;
				font-size: 14px;
				font-weight: 550;
				color: #1e1c1c;
				// color:#000;
				// font-weight: bold;
					
			}

			.navTopPhone-div {
				img {
					float: left;
					// width: 46px;
					// height: 46px;
					width: 25px;
					height: 25px;
				}

				.navTopPhoneText {
					float: left;
					font-size: 16px;
					margin-left: 15px;
				

					p {
						// font-size: 36px;
						font-size: 20px;
						color: #f96a00;
					}
				}
			}

		}
	}

	.chengDiv {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		// width: 550px;
		width: 700px;
		
	}
</style>
