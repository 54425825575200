<template>
  <div>
    <headtop></headtop>
    <img class="pageenter-banner" @click="gotoqiye" src="../assets/img/enterprise/banner.png" />
    <div class="pageenter-top">
      <h2 class="pageenter-titleone">零工平台赋能企业</h2>
      <el-row class="pageenter-row">
        <el-col :span="5">
          <img class="row-img" src="../assets/img/enterprise/tabone.png" />
          <p class="row-title">一、用工荒、招工难</p>
          <div class="row-content">
            热企行业在招工的问题上，最突出的问题就是很难找到合适的人选，尤其是短期内很难找到合适的工人；
          </div>
        </el-col>
        <el-col :span="5" :offset="1">
          <img class="row-img" src="../assets/img/enterprise/tabone.png" />
          <p class="row-title">二、成本高、流动大</p>
          <div class="row-content">
            因为热企在每年的供热季，以及大小修的季节，从设备的更新，到各种零部件的维修和维护，以及工人开支，成为企业不堪重负，而且工人的不稳定性强，导致流动性大；
          </div>
        </el-col>
        <el-col :span="5" :offset="1">
          <img class="row-img" src="../assets/img/enterprise/tabone.png" />
          <p class="row-title">三、赋税重、风险大</p>
          <div class="row-content">
            就用工企业而言，一年所承担的赋税越来越高，其中涵盖个人所得税、企业所得税、增值税，此外企业用工因为多为临时用工，这样没有保险，导致巨额的赔偿，导致风险大；
          </div>
        </el-col>
        <el-col :span="5" :offset="1">
          <img class="row-img" src="../assets/img/enterprise/tabone.png" />
          <p class="row-title">票据复杂、结算难</p>
          <div class="row-content">
            因为用工的时候，无论是劳务派遣，还是人力外包，因为无票、或者票据不正规导致利润入账，以及增值税专用发票上有很大的难度
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="pageenter-centerleft">
      <img src="../assets/img/enterprise/oneleft.png" alt="" />
      <div class="centerleftone-text">
        <h2 class="text-title">企业端</h2>
        <div class="text-border"></div>
        <div class="text-content">
          破解企业招工难、成本高、赋税重、结算难的四大瓶颈，为企业减负增收，让企业轻装上阵
        </div>
      </div>
    </div>

    <div class="pageenter-centerright">
      <img src="../assets/img/enterprise/oneright.png" alt="" />
      <div class="centerleftone-text">
        <h2 class="text-title">工人端</h2>
        <div class="text-border"></div>
        <div class="text-content">
          为一些普通劳动者提供更多的找零活的机会，在找活的时候，能迅速儿快捷地找到合适的活，增加收入、缓解生活压力
        </div>
      </div>
    </div>

    <div class="pageenter-centerleft">
      <img src="../assets/img/enterprise/twoleft.png" alt="" />
      <div class="centerleftone-text">
        <h2 class="text-title">客户端</h2>
        <div class="text-border"></div>
        <div class="text-content">
          一方面可以通过客户端发布自己用工信息，进而尽快找到合适的人，此外还可以根据自己职业技能，找到适合自己的零活
        </div>
      </div>
    </div>

    <div class="pageenter-bottom">
      <div class="bottom-kuang">
        <!-- <p>
          套用模型省时省力，不用冥思苦想，但是，每一个需要设计的内容和体系应该是迥然不同的，所以每一个设计方案也都应该是不可复制的
        </p> -->
        <el-button type="primary" class="bottom-button" @click="gotoqiye">入驻企业</el-button>
      </div>
    </div>

    <img class="pageenter-bottomimg" src="../assets/img/enterprise/bottomkuang.png">

    <foot></foot>
  </div>
</template>

<script>
import headtop from "../components/header";
import foot from "../components/footer";
export default {
  name: "VueEnterprise",
  components: {
    headtop,
    foot,
  },
  data() {
    return {};
  },
  mounted() {
    window.scrollTo(0,0);
  },
  methods: {
    gotoqiye(){
       window.location.href = "https://seller.odd-job.net/page/login/index.html"
    }
  },
};
</script>

<style lang="scss" scoped>
.pageenter-banner {
  width: 100%;
}

.pageenter-top {
  padding: 160px 0;
  .pageenter-titleone {
    text-align: center;
    margin: 20px 0;
    font-size: 42px;
  }

  .pageenter-row {
    width: 80%;
    margin: 0 10%;
    margin-top: 80px;
    .row-img {
      width: 72px;
      height: 72px;
      display: block;
      margin: 0 auto;
    }
    .row-title {
      font-size: 24px;
      font-weight: 400;
      color: #333333;
      text-align: center;
      margin: 20px 0;
    }
    .row-content {
      font-size: 18px;
      font-weight: 400;
      color: #666666;
      text-align: center;
    }
  }
}

.pageenter-centerleft {
  width: 100%;
  position: relative;
  margin-bottom:150px;
  img {
    width: 100%;
  }
  .centerleftone-text {
    position: absolute;
    left: 10%;
    top: 35%;
    .text-title {
      color: #fff;
      font-size: 42px;
    }
    .text-border {
      width: 62px;
      height: 4px;
      background: #ffffff;
      border-radius: 6px;
      margin: 20px 0 30px;
    }
    .text-content {
      color: #fff;
      font-size: 16px;
      width: 547px;
      letter-spacing: 1px;
    }
  }
}

.pageenter-centerright {
  width: 100%;
  position: relative;
  margin-bottom: 150px;
  img {
    width: 100%;
  }
  .centerleftone-text {
    position: absolute;
    right: 10%;
    top: 35%;
    .text-title {
      color: #fff;
      text-align: right;
      font-size: 42px;
    }
    .text-border {
      width: 62px;
      height: 4px;
      background: #ffffff;
      border-radius: 6px;
      margin: 20px 0 30px auto;
    }
    .text-content {
      color: #fff;
      font-size: 16px;
      width: 547px;
      letter-spacing: 1px;
      text-align: right;
    }
  }
}

.pageenter-bottom {
  padding-bottom: 150px;
  .bottom-kuang {
    width: 80%;
    // background: #f4f5f9;
    border-radius: 4px;
    // padding: 74px 0;
    margin: 0 10%;
    p {
      font-size: 16px;
      font-weight: 400;
      color: #0e6db4;
      text-align: center;
    }
    .bottom-button {
        padding:20px  160px;
      background: #67a5e1;
      border-radius: 10px;
      color:#fff;
      margin: 0 auto;
      font-size: 24px;
      margin-top: 55px;
      display: block;
    }
  }
}

.pageenter-bottomimg{
    width: 100%;
}
</style>